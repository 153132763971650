import React, { useEffect, useState } from 'react';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import withTrans from '../i18n/withTranslation';


const FaqPage = ({ data, t }) => {
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (window.location.hash) {
      setOpenIndex(window.location.hash.slice(1));
    }
  }, []);

  const getContent = (translation) => {
    return { __html: `${t(translation)}` }
  }

  return (
    <>
      <Layout
        title={t('site.pages.FaqPage.meta.title')}
        description={t('site.pages.FaqPage.meta.description')}
        img={data.header.childImageSharp.fluid.src}
      >
        <Hero
          isDark={true}
          title={t('site.pages.FaqPage.title')}
          alt={t('site.pages.FaqPage.headerAlt')}
          img={data.header.childImageSharp.fluid.src}
        />

        <div className="container my-5">

          <div id="accordion">


            {t('site.pages.FaqPage.list').map((el, index) => {
              return (
                <div className="card" key={index}>
                  <div id={index} className="fixanchor"></div>
                  <div className="card-header py-3 text-center" id={"heading" + index}
                    data-toggle="collapse"
                    data-target={"#collapse" + index}
                    aria-expanded={index == openIndex}
                    aria-controls={"collapse" + index}
                  >
                    <h5 className="mb-0">
                      <span className="faq-question">
                        {el.question}
                      </span>
                    </h5>
                  </div>
                  <div id={"collapse" + index} className={"collapse " + (index == openIndex && "show")} aria-labelledby={"heading" + index} data-parent="#accordion">
                    <div className="card-body" dangerouslySetInnerHTML={getContent(el.text)}></div>
                  </div>
                </div>
              )
            }
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query FaqPage {
          header: file(relativePath: {eq: "faq-hero.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
          src
        }
      }
    }
  }
`;

export default withTrans(FaqPage);